<template>
  <div class="voucher_offer">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/gift-voucher-offers">Gift Voucher offers</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.title_en }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="pl-lg-9 px-sm-7 pt-sm-7 pt-3 px-3 item-content">
        <div class="text-h6 font-weight-bold">Voucher offer details</div>
        <div class="text--text">Please, provide information about voucher in both Arabic and English languages</div>
        <div class="my-5 d-flex align-center flex-wrap">
          <div class="font-weight-bold">Status</div>
          <v-btn-toggle class="group-btn ms-5" v-model="data.status" @change="updateStatus">
            <v-btn class="text-body-2" value="listed" outlined height="36">Listed</v-btn>
            <v-btn class="text-body-2" value="unlisted" outlined height="36">Unlisted</v-btn>
          </v-btn-toggle>
        </div>
        <div class="font-weight-bold mb-2">Title</div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-textarea
            class="field46 width100 me-md-3"
            v-model="data.title_en"
            placeholder="Title"
            :error-messages="titleEnErrors"
            auto-grow
            rows="1"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
          <v-textarea
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.title_ar"
            placeholder="عنوان"
            :error-messages="titleArErrors"
            auto-grow
            rows="1"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
        </div>
        <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
          <v-textarea
            class="width100 me-md-3"
            v-model="data.description_en"
            placeholder="Description"
            :error-messages="desEnErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
          <v-textarea
            class="width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.description_ar"
            placeholder="وصف كامل"
            :error-messages="desArErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
        </div>
        <div class="d-flex flex-md-row flex-column">
          <div class="me-md-3">
            <div class="font-weight-bold mb-4 mt-n2">Price</div>
            <v-text-field
              class="field46 price-field mt-n2"
              v-model="data.priceR"
              placeholder="0"
              suffix="SAR"
              :error-messages="priceErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-text-field>
          </div>
          <div class="mx-md-3">
            <div class="font-weight-bold mb-4 mt-n2">VAT type</div>
            <v-select
              class="field46 price-field mt-n2"
              v-model="data.vat_option"
              :items="typeList"
              item-text="title"
              item-value="id"
              return-object
              placeholder="Select type"
              :error-messages="typeErrors"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-select>
          </div>
          <v-checkbox v-model="data.vat_included" color="success" class="mt-md-8 mt-0 mb-md-0 mb-6 ms-md-3" hide-details>
            <template v-slot:label>
              <span class="black--text">VAT included in price</span>
            </template>
          </v-checkbox>
        </div>
        <div class="font-weight-bold mb-3 mt-n2">Upload logo</div>
        <v-card
          @click="onFileChange($event)"
          flat
          class="d-flex align-center justify-center card-img"
          max-width="320"
          width="100%"
          height="320"
        >
          <div class="primary--text text-center" v-if="!data.picture">
            <v-img max-width="44" class="mx-auto primary-filter" src="@/assets/icon/upload.svg"></v-img>
            <div class="font-weight-bold">Upload picture</div>
            <div class="f13">600x600</div>
          </div>
          <img width="100%" height="318" v-else :src="data.picture.url" />
          <input type="file" accept="image/*" @change="onFileChange($event)" />
        </v-card>
      </div>
      <v-divider class="mt-5"></v-divider>
      <div class="d-flex align-center pl-lg-9 px-sm-7 py-3 px-3">
        <v-btn @click="modal = true" class="rounded" outlined depressed height="30" width="116">
          <span class="error--text">Remove</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="updateItem" class="rounded" depressed color="primary" height="30" width="116">Save</v-btn>
      </div>
    </v-card>
    <v-dialog v-model="modal" width="480">
      <v-card class="pa-4">
        <div class="text-h6 font-weight-bold mb-3">Do you really want to remove voucher offer {{ data.title_en }}?</div>
        <div class="text-end">
          <v-btn @click="modal = false" outlined class="rounded" color="gray lighten-1" width="106" height="30">
            <span class="text-body-2 black--text">Cancel</span>
          </v-btn>
          <v-btn @click="removeItem" depressed class="error rounded ms-3" width="106" height="30">
            <span class="text-body-2">Remove</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      file: '',
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getVoucherOfferItem');
    this.$store.dispatch('getSettingsVat', '?all=true');
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Voucher offer saved successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Voucher offer updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'updatePicture') {
        this.$notify({
          title: 'Done!',
          message: 'Voucher offer picture updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Voucher offer item removed successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async onFileChange(e) {
      this.file = e.target.files[0];
      this.updateItem();
    },
    async updateStatus() {
      await this.$store.dispatch('statusVoucherOfferItem', { status: this.data.status }).then(() => {
        this.notifi('update');
      });
    },
    async removeItem() {
      await this.$store.dispatch('removeVoucherOfferItem').then(() => {
        this.notifi('remove');
      });
    },
    async updateItem() {
      this.error = [];
      const formData = new FormData();
      formData.append('title_en', this.data.title_en);
      formData.append('title_ar', this.data.title_ar);
      formData.append('description_en', this.data.description_en);
      formData.append('description_ar', this.data.description_ar);
      formData.append('price', this.data.priceR * 100);
      formData.append('vat_option', this.data.vat_option.id);
      formData.append('vat_included', this.data.vat_included);
      this.file ? formData.append('picture', this.file) : '';
      await this.$store
        .dispatch('updateVoucherOfferItem', formData)
        .then(() => {
          this.notifi('saved');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.voucherOffer;
    },
    typeList() {
      return this.$store.getters.settingsVat;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    desEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    desArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    pictureErrors() {
      const errors = [];
      this.error.find((item) => item == 'picture__required') && errors.push('Please select picture');
      this.error.find((item) => item == 'picture__invalid') && errors.push('Selected picture is not valid');
      return errors;
    },
    priceErrors() {
      const errors = [];
      this.error.find((item) => item == 'price__required') && errors.push('Please enter price');
      this.error.find((item) => item == 'price__invalid') && errors.push('Provided price is not valid');
      return errors;
    },
    typeErrors() {
      const errors = [];
      this.error.find((item) => item == 'vat_option__required') && errors.push('Please select type');
      this.error.find((item) => item == 'vat_option__invalid') && errors.push('Selected type is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setVoucherOfferItem');
  },
};
</script>

<style lang="scss">
#app .voucher_offer {
  .price-field {
    .v-input__slot {
      max-width: 196px !important;
    }
  }
  .v-input {
    max-width: 371px;
  }
  .card-img {
    background: var(--v-primary-lighten3);
    border: 1px dashed var(--v-primary-base);
    img {
      object-fit: contain;
    }
  }
  @media screen and (max-width: 700px) {
    .v-input {
      max-width: initial !important;
    }
    .group-btn {
      display: grid;
      button {
        border-width: thin !important;
        border-radius: 4px !important;
      }
    }
  }
}
</style>
